import React from 'react';
import './css/LabyrinthLogo.css';

const LabyrinthLogo = (props) => {

    return (
        <svg className='labyrinth-logo' viewBox='0 0 510 560' height={props.size} width={props.size}>
            <g>
             <path d="M 20,55,40,20,80,20,100,55,140,55,160,20,200,20,220,55,260,55,280,20,320,20,340,55,380,55,400,20,440,20,460,55,500,55,520,89,500,124,520,158,500,193,520,228,500,262,520,297,500,331,520,366,500,401,520,435,500,470,460,470,440,504"/><path d="M 460,55,440,89"/><path d="M 140,55,160,89,140,124,160,158,200,158,220,193,200,228,160,228,140,262,100,262,80,228"/><path d="M 80,89,40,89,20,124,40,158,80,158,100,193,140,193"/><path d="M 200,158,220,124,200,89"/><path d="M 220,124,260,124"/><path d="M 280,89,320,89,340,124,380,124,400,89"/><path d="M 340,124,320,158,280,158"/><path d="M 380,124,400,158,440,158,460,124"/><path d="M 440,158,460,193,440,228,460,262,440,297,400,297,380,262"/><path d="M 400,297,380,331,400,366,440,366,460,401,440,435,400,435,380,470,400,504"/><path d="M 460,331,500,331"/><path d="M 380,470,340,470,320,435"/><path d="M 340,470,320,504,280,504,260,470,220,470,200,504,160,504,140,470,100,470,80,504,40,504,20,470,40,435,20,401,40,366,20,331,40,297,20,262,40,228,20,193,40,158"/><path d="M 80,366,40,366"/><path d="M 140,470,160,435"/><path d="M 80,435,100,401,140,401,160,366,140,331,100,331,80,297"/><path d="M 140,331,160,297,200,297,220,262,260,262,280,297,260,331,280,366,320,366,340,401,380,401"/><path d="M 160,366,200,366,220,331"/><path d="M 200,366,220,401,200,435"/><path d="M 280,366,260,401,280,435,260,470"/><path d="M 220,193,260,193,280,228,320,228,340,193,380,193,400,228"/><path d="M 320,228,340,262,320,297,340,331"/>   
            </g>
        </svg>
    )
}

export default LabyrinthLogo;
